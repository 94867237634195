import React, { useEffect, useState } from "react";
import ShareModal from "../../components/ShareModal/ShareModal";
import { ShareDetailPageStyle } from "./ShareDetailPageStyle.style";
import { BASE_URL } from "../../constants/api-urls";
import axios from "axios";
import { useLoginContext } from "../../context";
import { getRefreshedToken } from "../../utils";
import { SPOTIFY_TOKEN } from "../../constants/storage";

function ShareDetailPage() {
  const [shareSongModal, setShareSongModal] = useState(true);
  const [ViewDetailData, setViewDetailData] = useState({});
  const [currentPlaylistData, setCurrentPlaylistData] = useState<any>({});
  const [PlaylistRefreshRecord, setPlaylistRefreshRecord] = useState([]);
  const { state: loginState, dispatch: loginDispatch } = useLoginContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // @ts-ignore
        const url = new URL(window.location);
        const uri = url.searchParams.get("uri");
        const id = url.searchParams.get("id");
        const playlistId = url.searchParams.get("playlistId");
        const refreshToken = url.searchParams.get("ref");
        const position = url.searchParams.get("position");
        const originalDate = url.searchParams.get("originalDate");

        getRefreshedToken(refreshToken).then(async (response) => {
          console.log(response, "dasdasdadasdas");
          let token = response?.data?.access_token;
          // localStorage.setItem(SPOTIFY_TOKEN, response?.data?.access_token);
          // loginDispatch({
          //   type: "ADD_TOKEN",
          //   payload: response?.data?.access_token,
          // });
          if (token) {
            if (uri && playlistId) {
              const config = {
                song_uri: uri,
                selected_playlist_id: playlistId,
              };

              // Post request to get song details from your backend
              const response = await axios.post(
                `${BASE_URL}/getSongDetails`,
                config
              );
              console.log(response, "response");

              // Get request to Spotify API to get additional song details
              const responseSong = await axios.get(
                `https://api.spotify.com/v1/tracks/${id}`,
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              if (playlistId) {
                axios(`https://api.spotify.com/v1/playlists/${playlistId}`, {
                  method: "GET",
                  headers: { Authorization: "Bearer " + token },
                })
                  .then((responses) => {
                    let currentPlaylistContent = responses.data;
                    setCurrentPlaylistData(currentPlaylistContent);

                    // Check if both responses are valid
                    if (response?.data && responseSong?.data) {
                      console.log(
                        response.data,
                        responseSong.data,
                        currentPlaylistContent,
                        "Combined Data"
                      );
                      setPlaylistRefreshRecord(
                        response.data?.data?.PlaylistRefreshRecords
                      );
                      // Set the view detail data with the combined response
                      setViewDetailData({
                        ...ViewDetailData,
                        notes: response.data?.data?.songData?.notes || "",
                        track: {
                          name: responseSong.data?.name || "",
                          artists: [
                            {
                              name: responseSong.data?.artists[0]?.name || "",
                            },
                          ],
                          album: {
                            images: [
                              {
                                url:
                                  responseSong.data?.album?.images[0]?.url ||
                                  "",
                              },
                            ],
                          },
                          uri: responseSong.data?.songData?.uri || "",
                        },
                        popularity: responseSong.data?.popularity,
                        position: position,
                        added_at: originalDate || 
                          response.data?.data?.songData?.started_time ||
                          currentPlaylistContent?.tracks?.items[0].added_at ||
                          "-",
                        endTime:
                          response.data?.data?.songData?.end_time === "null"
                            ? "-"
                            : response.data?.data?.songData?.end_time || "",
                        song_uri: responseSong.data?.songData?.uri || "",
                        selected_playlist_id:
                          response.data?.data?.songData?.selected_playlist_id ||
                          "",
                      });
                    }
                  })
                  .catch(async (error) => {});
              }
            }
          }
        });
      } catch (error) {
        console.error("Error fetching song details:", error);
      }
    };

    fetchData();
  }, [setViewDetailData]);
  return (
    <ShareDetailPageStyle>
      <ShareModal
        isModalVisible={shareSongModal}
        setIsModalVisible={() => {
          setShareSongModal(false);
          window.location.href = "/";
        }}
        editType={"viewDetail"}
        ViewDetailData={ViewDetailData}
        setViewDetailData={setViewDetailData}
        currentPlaylistData={currentPlaylistData}
        PlaylistRefreshRecord={PlaylistRefreshRecord}
        showCopyUrl={false}
        scheduleSong={false}
      />
    </ShareDetailPageStyle>
  );
}

export default ShareDetailPage;
